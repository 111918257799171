<template>
  <div
    :class="badgeClass(item)"
    :style="badgeStyle(item)"
  >
    <span
      class="product-label__badge_left"
      :style="borderStyle(item, 'Right')"
    />
    <span
      class="product-label__badge_right"
      :style="borderStyle(item, 'Left')"
    />
    <span class="product-label__badge_text">
      {{ getPromotionText(item.text) }}
      <span v-if="getIsShowPromotionDate(item)">
        {{ getPromotionDateText(item) }}
      </span>
    </span>
  </div>
</template>

<script>
import { getFormatTimer } from 'theme/helpers/timer';

export default {
  name: 'PromotionBadge',
  props: {
    item: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    badgeClass () {
      return (item) => `product-label__badge product-label__badge-position-${item.position}`;
    },
    badgeStyle () {
      return (item) => item && item.color ? { backgroundColor: item.color } : {};
    },
    borderStyle () {
      return (item, side) => ({ [`border${side}`]: `var(--spacer-4) solid ${item.color}` });
    }
  },
  methods: {
    getPromotionText (text) {
      return text || ''
    },
    getIsShowPromotionDate (label) {
      return label && label.date_from && label.date_to && (label.add_date || label.add_countdown);
    },
    getPromotionDateText (label) {
      if (label && label.add_date && label.add_countdown && label.date_to) {
        const dateText = label.add_date
          ? `${this.$t('to')} ${this.getPromotionDay(label)}.${this.getPromotionMonth(label)}`
          : label.add_countdown && label.date_to
            ? ` | ${getFormatTimer(label.date_to).days} ${this.getLabelDay(getFormatTimer(label.date_to).days)}`
            : '';

        return dateText;
      }
      return '';
    }
  }
};
</script>
